import * as actionTypes from '../consts/actionTypes';
import { ICruiseRecommendation } from '../interfaces/ISearchApi';
import { IZoneTab } from '../interfaces/IZoneTabs';
import { IBreadcrumb, IDeck } from '../interfaces';
import { ICruiseOffers } from '../interfaces/IUGCReviews';

export interface ICompanyDetailPageReducer {
    companyId: number;
    title: string;
    headerImage: string;
    headline: string;
    subHeadline: string;
    companyName: string;
    description: string;
    factsAndFigures: string;
    logo: string;
    url: string;
    seo: ISeo;
    advisor: IAdvisor;
    cruises: ICruiseRecommendation[];
    ships: IShip[];
    images: string[];
    breadcrumbs: IBreadcrumb[];
    offers: ICruiseOffers;
    zoneTabs: IZoneTab[] | null;
}

export const initialState: ICompanyDetailPageReducer = {
    companyId: null,
    title: null,
    headerImage: null,
    headline: null,
    subHeadline: null,
    description: null,
    factsAndFigures: null,
    logo: null,
    url: null,
    companyName: null,
    seo: {
        text: null,
        titleOg: null,
        robotsNoFollow: null,
        robotsNoIndex: null,
        metadesc: null,
    },
    advisor: {
        headline: null,
        text: null,
        name: null,
        image: null,
        phone: null,
    },
    cruises: [],
    ships: [],
    images: [],
    breadcrumbs: [],
    offers: null,
    zoneTabs: null,
};
export interface ISeo {
    text: string;
    titleOg: string;
    robotsNoFollow: boolean;
    robotsNoIndex: boolean;
    metadesc: string;
}

export interface IAdvisor {
    headline: string;
    text: string;
    name: string;
    image: string;
    phone: string;
}

export interface IShip {
    category: number;
    length: number;
    manufactureYear: number;
    maxPercentDiscount: number;
    minPrice: number;
    minPriceDualCurrency?: number;
    currency: string;
    dualCurrency?: string;
    numDecks: number;
    passengers: number;
    teaserDescription: string;
    teaserImage: string;
    title: string;
    companyImage?: string;
    url?: string;
    child?: any;
    discountThreshold?: any;
    logoImageUrl?: string;
    teaserImageUrl?: string;
    decks?: IDeck[];
}

export function companyDetailPage(
    state: ICompanyDetailPageReducer = initialState,
    action,
): ICompanyDetailPageReducer {
    switch (action.type) {
        case actionTypes.loadCompanyDetailPageData:
            const companyPage = action.payload;
            return { ...state, ...companyPage };
        default:
            return state;
    }
}
