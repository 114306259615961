import loadable from '@loadable/component';
import * as React from 'react';
import { getFooterByPageType } from '../actions/footerActions';
import * as actionTypes from '../consts/actionTypes';
import {
    companyPage,
    companyTermsDetailPage,
    companyTermsOverviewPage,
    contactPage,
    destinationPage,
    faqPage,
    favoritePage,
    landingPage,
    lpCompanyOverview,
    lpDestinationOverview,
    mediaOfferPage,
    newsletterPage,
    roundtripDetailPage,
    roundtripOverviewPage,
    standardContentPage,
    themeOverviewPage,
    welcomePage,
} from '../consts/pageTypes';
import { NotFoundError } from '../errors/NotFoundError';
import { get } from '../helpers/http';
import { stripHtmlTags } from '../helpers/string';
import { ISeo } from '../interfaces/ISeo';
import { initialState as contactFormInitialState } from '../reducers/contactForm/contactForm';
import { initialState as dynamicFormInitialState } from '../reducers/dynamicForm/dynamicForm';
import { defaultSeo } from '../reducers/pageData';
import { setContactFormInitialData } from './contactFormActions';
import { getDynamicFormByIdentifier, setDynamicFormInitialData } from './dynamicFormAction';
import { changeRouteSuccess } from './routerActions';
import { dynamicFormBuilder } from '../helpers/dynamicFormBuilder';
import DestinationOverviewPage from '../pages/Destination/DestinationOverviewPage';

export const pageRouteAction = (context) => {
    const storeState = context.store.getState();
    const { apiHost, countryCode } = storeState.pageData.appConfig;
    const { isBrowser } = storeState.router;
    const { path, params, baseUrl, waitForAction, action } = context;
    // @TODO: Create a new mongo property to handle it;
    const brandName = countryCode === 'au' ? 'CruiseAway' : 'Dreamlines';

    const defaultActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: standardContentPage,
                seo: seo,
            }),
        );

        context.store.dispatch(setDynamicFormInitialData(dynamicFormInitialState) as any);

        const StandardContentPage = loadable(() =>
            import('../pages/StandardContent/StandardContentPage'),
        );

        return { component: <StandardContentPage />, context };
    };

    const contactActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: contactPage,
                seo: seo,
            }),
        );

        context.store.dispatch(setContactFormInitialData(contactFormInitialState) as any);
        const ContactPage = loadable(() => import('../pages/Contact/ContactPage'));

        return { component: <ContactPage />, context };
    };

    const faqActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: faqPage,
                seo: seo,
            }),
        );

        const FAQPage = loadable(() => import('../pages/FAQ/FAQPage'));

        return { component: <FAQPage />, context };
    };

    const landingpageActions = (seo: ISeo = defaultSeo, title: string = '') => {
        const result = get(`${apiHost}/footer/harbour`);
        result.then((response) => {
            context.store.dispatch(getFooterByPageType(response) as any);
        });

        if (!seo.titleOg) seo.titleOg = `${title} | ${brandName}`;
        if (!seo.metadesc) {
            const metadesc = (seo.metadesc = seo?.text?.split('.')[0] || seo?.text || '');
            seo.metadesc = stripHtmlTags(metadesc);
        }
        if (!seo.keywords) seo.keywords = title?.toLowerCase();

        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: landingPage,
                seo: seo,
            }),
        );

        const LandingPage = loadable(() => import('../pages/Landing/LandingPage'));

        return { component: <LandingPage />, context };
    };

    const roundtripDetailPageActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: roundtripDetailPage,
                seo: seo,
            }),
        );

        const RoundtripDetailPage = loadable(() =>
            import('../pages/RoundtripDetail/RoundtripDetailPage'),
        );

        return { component: <RoundtripDetailPage />, context };
    };

    const roundtripOverviewPageActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: roundtripOverviewPage,
                seo: seo,
            }),
        );

        const RoundtripOverviewPage = loadable(() =>
            import('../pages/RoundtripOverview/RoundtripOverviewPage'),
        );

        return { component: <RoundtripOverviewPage />, context };
    };

    const favoritepageActions = (seo: ISeo = defaultSeo, title: string = '') => {
        if (!seo.titleOg) {
            seo.titleOg = title;
        }
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: favoritePage,
                seo,
            }),
        );
        context.store.dispatch({ type: actionTypes.showLoadingIndicator });

        const FavoritePage = loadable(() => import('../pages/Favorite/FavoritePage'));

        return { component: <FavoritePage />, context };
    };

    const destinationDetailPageActions = (seo: ISeo = defaultSeo) => {
        const result = get(`${apiHost}/footer/destination`);
        result.then((response) => {
            context.store.dispatch(getFooterByPageType(response) as any);
        });

        const response = dynamicFormBuilder(countryCode);

        context.store.dispatch(setDynamicFormInitialData(dynamicFormInitialState) as any);
        context.store.dispatch(getDynamicFormByIdentifier(response) as any);
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: destinationPage,
                seo: seo,
            }),
        );

        const DestinationDetailPage = loadable(() =>
            import('../pages/Destination/DestinationDetailPage'),
        );

        return { component: <DestinationDetailPage />, context };
    };

    const themeOverviewActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: themeOverviewPage,
                seo: seo,
            }),
        );

        const ThemeOverviewPage = loadable(() => import('../pages/Theme/ThemeOverview'));

        return { component: <ThemeOverviewPage />, context };
    };

    const destinationOverviewActions = (seo: ISeo = defaultSeo) => {
        const result = get(`${apiHost}/footer/destination`);
        result.then((response) => {
            context.store.dispatch(getFooterByPageType(response) as any);
        });

        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: lpDestinationOverview,
                seo: seo,
            }),
        );

        return { component: <DestinationOverviewPage />, context };
    };

    const companyDetailActions = (seo: ISeo = defaultSeo) => {
        const result = get(`${apiHost}/footer/cruiseline`);
        result.then((response) => {
            context.store.dispatch(getFooterByPageType(response) as any);
        });

        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: companyPage,
                seo: seo,
            }),
        );

        const response = dynamicFormBuilder(countryCode);

        context.store.dispatch(setDynamicFormInitialData(dynamicFormInitialState) as any);
        context.store.dispatch(getDynamicFormByIdentifier(response) as any);

        const CompanyDetailPage = loadable(() => import('../pages/Company/CompanyDetailPage'));

        return { component: <CompanyDetailPage />, context };
    };

    const companyOverviewActions = (seo: ISeo = defaultSeo) => {
        const result = get(`${apiHost}/footer/cruiseline`);
        result.then((response) => {
            context.store.dispatch(getFooterByPageType(response) as any);
        });

        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: lpCompanyOverview,
                seo: seo,
            }),
        );

        const CompanyOverviewPage = loadable(() => import('../pages/Company/CompanyOverviewPage'));

        return { component: <CompanyOverviewPage />, context };
    };

    const newsletterActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: newsletterPage,
                seo: seo,
            }),
        );

        const NewsletterPage = loadable(() => import('../pages/Newsletter/NewsletterPage'));

        return { component: <NewsletterPage />, context };
    };

    const welcomeActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: welcomePage,
                seo: seo,
            }),
        );

        const WelcomePage = loadable(() => import('../pages/Newsletter/WelcomePage'));

        return { component: <WelcomePage />, context };
    };

    const companyTermsListActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: companyTermsOverviewPage,
                seo: seo,
            }),
        );

        const CompanyTermsList = loadable(() => import('../pages/CompanyTerms/CompanyTermsList'));

        return { component: <CompanyTermsList />, context };
    };

    const companyTermsDetailActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: companyTermsDetailPage,
                seo: seo,
            }),
        );

        const CompanyTermsDetail = loadable(() =>
            import('../pages/CompanyTerms/CompanyTermsDetail'),
        );

        return { component: <CompanyTermsDetail />, context };
    };

    const mediaOfferPageActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: mediaOfferPage,
                seo: seo,
            }),
        );

        const MediaOfferPage = loadable(() => import('../pages/MediaOffer/MediaOffer'));

        return { component: <MediaOfferPage />, context };
    };

    if (waitForAction) {
        const searchParams = new URLSearchParams(context.search);
        searchParams.append('path', context.path);
        const query = decodeURIComponent(searchParams.toString());
        const url = `${apiHost}/page?${query}`;

        return get(url)
            .then((resp) => {
                if (
                    isBrowser &&
                    ['lpCompanyDetail', 'destination_detail'].includes(resp.pageType)
                ) {
                    // This is forcing full page load when the user get in the ship page
                    // by client side navigation
                    // @TODO Remove after the LP test
                    window.location.href = context.path;
                    return new Promise<any>((resolve) =>
                        setTimeout(() => {
                            resolve(undefined);
                        }, 10000),
                    );
                }

                switch (resp.pageType) {
                    case 'standard_content':
                        context.store.dispatch({
                            type: actionTypes.loadStandardContentPageData,
                            payload: resp.content.cmsData,
                        });
                        return defaultActions(resp.content.seo);
                    case 'contact':
                        context.store.dispatch({
                            type: actionTypes.loadContactPageData,
                            payload: resp.content,
                        });
                        return contactActions(resp.content.seo);
                    case 'faq':
                        context.store.dispatch({
                            type: actionTypes.loadFAQPageData,
                            payload: resp.content,
                        });
                        return faqActions(resp.content.seo);
                    case 'landingpage':
                        context.store.dispatch({
                            type: actionTypes.loadLandingPageData,
                            payload: resp.content,
                        });
                        return landingpageActions(resp.content.seo, resp.content.title);
                    case 'favorite':
                        context.store.dispatch({
                            type: actionTypes.loadFavoritePageData,
                            payload: resp.content.cmsData,
                        });
                        return favoritepageActions(resp.content.seo, resp.content.cmsData.title);
                    case 'destination_detail':
                        context.store.dispatch({
                            type: actionTypes.loadDestinationDetailPageData,
                            payload: resp.content,
                        });
                        return destinationDetailPageActions(resp.content.seo);
                    case 'pageteaser-list':
                        context.store.dispatch({
                            type: actionTypes.loadThemeOverviewPageData,
                            payload: resp.content,
                        });
                        return themeOverviewActions(resp.content.seo);
                    case lpDestinationOverview:
                        context.store.dispatch({
                            type: actionTypes.loadDestinationOverviewPageData,
                            payload: resp.content,
                        });
                        return destinationOverviewActions(resp.content.seo);
                    case 'lpCompanyDetail':
                        context.store.dispatch({
                            type: actionTypes.loadCompanyDetailPageData,
                            payload: resp.content,
                        });
                        return companyDetailActions(resp.content.seo);
                    case 'lpCompanyOverview':
                        context.store.dispatch({
                            type: actionTypes.loadCompanyOverviewPageData,
                            payload: resp.content,
                        });
                        return companyOverviewActions(resp.content.seo);
                    case 'newsletter_landingpage':
                        context.store.dispatch({
                            type: actionTypes.loadNewsletterPageData,
                            payload: resp.content,
                        });
                        return newsletterActions(resp.content.seo);
                    case 'welcome_landingpage':
                        context.store.dispatch({
                            type: actionTypes.loadNewsletterPageData,
                            payload: resp.content,
                        });
                        return welcomeActions(resp.content.seo);
                    case 'roundtripDetail':
                        context.store.dispatch({
                            type: actionTypes.loadRoundtripDetailPageData,
                            payload: resp.content,
                        });
                        return roundtripDetailPageActions(resp.content.seo);
                    case 'roundtripOverview':
                        context.store.dispatch({
                            type: actionTypes.loadRoundtripOverviewPageData,
                            payload: resp.content,
                        });
                        return roundtripOverviewPageActions(resp.content.seo);
                    case 'companyTermsOverview':
                        context.store.dispatch({
                            type: actionTypes.loadCompanyTermsList,
                            payload: resp.content,
                        });
                        return companyTermsListActions(resp.content.seo);
                    case 'companyTermsDetail':
                        context.store.dispatch({
                            type: actionTypes.loadCompanyTerms,
                            payload: resp.content,
                        });
                        return companyTermsDetailActions(resp.content.seo);
                    case 'lpMediaOffer':
                        context.store.dispatch({
                            type: actionTypes.loadMediaOfferPageData,
                            payload: resp.content,
                        });
                        return mediaOfferPageActions(resp.content.seo);
                    case 'lpZone':
                        context.store.dispatch({
                            type: actionTypes.loadLandingPageData,
                            payload: resp.content,
                        });
                        return landingpageActions(resp.content.seo, resp.content.title);
                    default:
                        break;
                }
            })
            .catch((e) => {
                console.error(e);
                throw new NotFoundError('Page not found');
            });
    } else {
        const pageType = context.store.getState().router.pageType;
        if (pageType === standardContentPage) {
            return defaultActions(context.store.getState().pageData.seo);
        } else if (pageType === contactPage) {
            return contactActions(context.store.getState().pageData.seo);
        } else if (pageType === faqPage) {
            return faqActions(context.store.getState().pageData.seo);
        } else if (pageType === landingPage) {
            return landingpageActions(
                context.store.getState().pageData.seo,
                context.store.getState().pageData.title,
            );
        } else if (pageType === favoritePage) {
            return favoritepageActions(context.store.getState().pageData.seo);
        } else if (pageType === destinationPage) {
            return destinationDetailPageActions(context.store.getState().pageData.seo);
        } else if (pageType === themeOverviewPage) {
            return themeOverviewActions(context.store.getState().pageData.seo);
        } else if (pageType === lpDestinationOverview) {
            return destinationOverviewActions(context.store.getState().pageData.seo);
        } else if (pageType === companyPage) {
            return companyDetailActions(context.store.getState().pageData.seo);
        } else if (pageType === lpCompanyOverview) {
            return companyOverviewActions(context.store.getState().pageData.seo);
        } else if (pageType === newsletterPage) {
            return newsletterActions(context.store.getState().pageData.seo);
        } else if (pageType === welcomePage) {
            return welcomeActions(context.store.getState().pageData.seo);
        } else if (pageType === roundtripDetailPage) {
            return roundtripDetailPageActions(context.store.getState().pageData.seo);
        } else if (pageType === roundtripOverviewPage) {
            return roundtripOverviewPageActions(context.store.getState().pageData.seo);
        } else if (pageType === companyTermsOverviewPage) {
            return companyTermsListActions(context.store.getState().pageData.seo);
        } else if (pageType === companyTermsDetailPage) {
            return companyTermsDetailActions(context.store.getState().pageData.seo);
        } else if (pageType === mediaOfferPage) {
            return mediaOfferPageActions(context.store.getState().pageData.seo);
        }
    }
};
