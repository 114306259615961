export const pageTypeMapper = {
    cruisepage: 'cruisePage',
    searchresults: 'searchResultPage',
    cruiseline: 'cruiselinePage',
    'ship/overview': 'shipOverviewPage',
    shippage: 'shipPage',
    lpCompanyDetail: 'companyPage',
    lpDestinationDetail: 'destinationPage',
    lpDestinationOverview: 'destinationOverviewPage',
    'booking-thank-you': 'thankYouPage',
    privacy: 'privacyPage',
    frontPage: 'frontPage',
    contactPage: 'contactPage',
    landingPage: 'landingPage',
    standardContentPage: 'standardContentPage',
    favorite: 'favoritePage',
    faqPage: 'faqPage',
    'pageteaser-list': 'themeOverviewPage',
    lpCompanyOverview: 'companyOverviewPage',
    newsletterPage: 'newsletterPage',
    'ship-position': 'shipPositionPage',
    roundtripDetail: 'roundtripPage',
    roundtripOverview: 'roundtripOverviewPage',
    welcomePage: 'welcomePage',
};
