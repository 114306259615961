import classnames from 'classnames';
import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import styled from '@emotion/styled';
import parse from 'html-react-parser';
import { IShipDescription } from '../../interfaces';
import { BreakpointsEnum } from '../../helpers/breakpoints';

export interface IFurtherInfo {
    description: string | IShipDescription;
}

const ToggleButton = styled.span`
    font-size: 14px;
    color: ${({ theme }) => theme.link.primary};
    display: none;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    cursor: pointer;

    @media screen and (min-width: ${BreakpointsEnum.md}) {
        display: flex;
    }

    svg {
        fill: ${({ theme }) => theme.link.primary};
        margin-left: 6px;
    }
    .rotate {
        transform-box: fill-box;
        transform-origin: center;
        transform: rotate(180deg);
    }
`;
const VISIBLE_HEIGHT = 415;

export const FurtherInfo = (props: IFurtherInfo) => {
    const { cruisePage } = useTypedSelector((state) => state);
    const { ship } = cruisePage;
    const description =
        typeof props.description === 'string'
            ? props.description
            : ((ship.description as any) as string);
    const facts = (ship.factsAndFigures as any) as string;
    const infoElement = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        const currentInfoElement = infoElement?.current;

        if (currentInfoElement && currentInfoElement.offsetHeight > VISIBLE_HEIGHT) {
            setShowMoreInfo(true);
        } else {
            setShowMoreInfo(false);
        }
    }, [infoElement?.current]);

    const [isOpen, toggleOpen] = React.useState(true);
    const [showMoreInfo, setShowMoreInfo] = React.useState(false);

    const toggleText = !isOpen ? __('load more', 'dreamlines') : __('show less', 'dreamlines');
    return (
        <div className="furtherInfo" ref={infoElement}>
            {facts && (
                <>
                    <div>{parse(facts)}</div>
                </>
            )}
            <div style={{ height: isOpen ? 'auto' : VISIBLE_HEIGHT, overflow: 'hidden' }}>
                {parse(description + '')}
            </div>
            {showMoreInfo && (
                <ToggleButton onClick={() => toggleOpen(!isOpen)}>
                    {toggleText}
                    <ArrowDownIcon className={classnames({ rotate: isOpen })} width={14} />
                </ToggleButton>
            )}
        </div>
    );
};
