import { colors } from './colors';

export const defaultThemedFooter = {
    textColor: colors.taupe[100],
    socialIconsColor: colors.azure[100],

    contactUsTextColor: colors.gunmetal[100],
    contactUsIconsColor: colors.black[100],
    contactUsBgColor: colors.seashell[100],

    breadcrumbsText: colors.azure[100],
    breadcrumbsTextActive: colors.gunmetal[50],
    breadcrumbsSeparator: colors.gunmetal[50],

    newsletterTitle: colors.white[100],
    newsletterOverlay: colors.darkCerulean[70],
    newsletterGdpr: colors.white[100],
    newsletterIconColor: colors.white[100],
    newsletterBg: colors.peacockBlue[100],

    highlightsTextColor: colors.white[100],
    highlightsIconColor: colors.white[100],
    highlightsBackground: colors.maastricht[100],

    bottomBackground: colors.taupe[100],
    bottomTextColor: colors.white[100],
};

export const defaultThemedBorder = {
    onLayer: 'rgba(245, 245, 245, 1)',
    onBg: 'rgba(240, 240, 240, 1)',
};

export const defaultThemedIcon = {
    primary: colors.gunmetal[100],
    secondary: colors.gunmetal[70],
    brandColor1: colors.azure[100],
    brandColor2: colors.azure[100],
};

export const defaultThemedLink = {
    primary: colors.azure[100],
    primaryHover: colors.cerulean[100],
};

export const defaultThemedBackground = {
    page: colors.desertStorm[100],
    layer: colors.white[100],
    layerSecondary: 'rgba(253,253,253, 1)',
    layerTertiary: 'rgba(248, 248, 248, 1)',
    brandColor1: colors.azure[100],
    brandColor1Inactive: colors.azure[20],
    brandColor2: colors.darkCerulean[100],
    brandColor3: colors.azure[100],
    brandColor4: colors.peacockBlue[100],
    'brandColor4-90': colors.peacockBlue[90],
    light1: colors.peacockBlue[6],
    light2: colors.peacockBlue[6],
    searchBox: 'rgba(0, 35, 59, 0.6)',
};

export const defaultThemedText = {
    color: {
        primary: colors.gunmetal[100],
        primary2: colors.gunmetal[70],
        primaryInverse: colors.white[100],
        secondary: colors.gunmetal[50],
        brand: colors.azure[100],
        error: colors.watermelon[100],
    },
    size: {
        paragraph: '14px',
    },
};

export const defaultThemedButton = {
    primary: {
        background: colors.azure[100],
        backgroundHover: colors.cerulean[100],
        backgroundDisabled: colors.freshAir[100],
        text: colors.white[100],
        textHover: colors.white[100],
        textDisabled: colors.white[100],
        icon: colors.white[100],
        iconHover: colors.white[100],
        iconDisabled: colors.white[100],
        border: colors.azure[100],
        borderHover: colors.cerulean[100],
        borderDisabled: colors.freshAir[100],
    },
    secondary: {
        background: colors.aliceBlue[100],
        backgroundHover: colors.sky[100],
        backgroundDisabled: colors.aliceBlue[100],
        text: colors.azure[100],
        textHover: colors.azure[100],
        textDisabled: colors.cornFlower[100],
        icon: colors.azure[100],
        iconHover: colors.azure[100],
        iconDisabled: colors.azure[100],
        border: colors.cornFlower[100],
        borderHover: colors.cornFlower[100],
        borderDisabled: colors.cornFlower[100],
    },
    tertiary: {
        background: colors.white[100],
        backgroundHover: colors.soapstone[100],
        backgroundDisabled: colors.soapstone[100],
        text: colors.gunmetal[100],
        textHover: colors.gunmetal[100],
        textDisabled: colors.gunmetal[50],
        icon: colors.gunmetal[100],
        iconHover: colors.gunmetal[100],
        iconDisabled: colors.gunmetal[50],
        border: colors.seashell[100],
        borderHover: colors.seashell[100],
        borderDisabled: colors.seashell[100],
    },
    quaternary: {
        background: colors.whiteSmoke[100],
        backgroundHover: colors.desertStorm[100],
        backgroundDisabled: colors.whiteSmoke[100],
        text: colors.gunmetal[100],
        textHover: colors.gunmetal[100],
        textDisabled: colors.gunmetal[50],
        icon: colors.gunmetal[100],
        iconHover: colors.gunmetal[100],
        iconDisabled: colors.gunmetal[50],
    },
    flat: {
        background: 'transparent',
        backgroundHover: 'transparent',
        backgroundDisabled: 'transparent',
        text: colors.azure[100],
        textHover: colors.azure[100],
        textDisabled: colors.gunmetal[50],
        icon: colors.azure[100],
        iconHover: colors.azure[100],
        iconDisabled: colors.gunmetal[50],
        border: 'none',
        borderHover: 'none',
        borderDisabled: 'none',
    },
};

export const defaultThemedAlert = {
    succeed: {
        background: colors.panache[100],
        border: colors.turtleGreen[20],
        icon: colors.turtleGreen[100],
    },
    info: {
        background: colors.azure[4],
        border: colors.azure[20],
        icon: colors.azure[100],
    },
    attention: {
        background: colors.heatWave[4],
        border: colors.heatWave[20],
        icon: colors.heatWave[100],
    },
    warning: {
        background: colors.watermelon[4],
        border: colors.watermelon[20],
        icon: colors.watermelon[100],
    },
    neutral: {
        background: colors.soapstone[100],
        border: colors.whiteSmoke[100],
        icon: colors.gunmetal[100],
    },
};

export const defaultThemedActionBar = {
    backgroundColor: colors.sky[100],
    borderColor: colors.freshAir[100],
    actionColor: colors.azure[100],
};

export const defaultThemedToggleButton = {
    backgroundColorActive: colors.azure[100],
    backgroundColor: colors.azure[20],
};

export const defaultThemedHeaderContacts = {
    titleColor: colors.azure[100],
    phoneColor: colors.gunmetal[100],
};

export const defaultThemedPagination = {
    backgroundColor: colors.white[100],
    backgroundColorActive: colors.darkCerulean[100],
    borderColor: colors.white[100],
    borderColorActive: colors.darkCerulean[100],
    textColor: colors.darkCerulean[100],
    textColorActive: colors.white[100],
};

export const defaultThemedProductPage = {
    tabs: {
        textColor: colors.darkCharcoal[100],
        textColorActive: colors.white[100],
        iconColor: colors.azure[100],
        iconColorActive: colors.white[100],
        borderColor: colors.seashell[100],
        borderColorActive: colors.azure[100],
        backgroundColor: colors.white[100],
        backgroundColorActive: colors.azure[100],
    },
    specialsHeaderBackground: colors.darkRaspberry[100],
    sectionHeader: {
        textColor: colors.white[100],
        backgroundColor: colors.peacockBlue[100],
    },
    requestButtons: {
        primaryColor: colors.azure[100],
        secondaryColor: colors.peacockBlue[100],
        hoverColor: colors.maastricht[100],
    },
};
