import React, { useState } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { clearFilterParams } from '../../actions/searchActions';
import { __ } from '../../helpers/TranslationService';
import { IHeader } from '../../interfaces/IHeader';
import { IStyles } from '../../interfaces/IStyles';
import { IState } from '../../reducers';
import { IAppConfig, IBrandConfig, IConfig } from '../../reducers/pageData';
import { IRouterReducer } from '../../reducers/router';
import { requestFormPage } from '../../consts/pageTypes';
import { BackLink } from './BackLink';
import { BurgerButton } from './BurgerButton';
import { Logo } from './Logo';
import { NavigationMenuContainer } from './NavigationMenuContainer';
import { TrustBadges } from '../TrustBadges/TrustBadges';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { PhoneIcon } from '../Icons/PhoneIcon';

const styles: IStyles = {
    container: {
        padding: '12px 16px 12px 16px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        width: '100%',
    },
    trustBadges: {
        flex: '0',
    },
};

const LogoWrapper = styled.div`
    max-width: 110px;
    @media (max-width: ${BreakpointsEnum.xss}) {
        display: none;
    }
`;
interface Props {
    header: IHeader;
    portalId: string;
    favoriteCount: number;
    favoritesUrl: string;
    phoneNumber: string;
    isMobile: boolean;
    appConfig: IAppConfig;
    brandConfig: IBrandConfig;
    config: IConfig;
    router: IRouterReducer;
}

type IProps = Props & typeof mapDispatchToProps;

const ContactBlockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    flex-grow: 1;
`;

const ContactBlockTitle = styled.div`
    color: ${({ theme }) => theme.headerContacts.titleColor};
    font-size: 10px;
    font-weight: 700;
    white-space: nowrap;
    margin-bottom: 4px;
`;

const ContactBlockPhone = styled.div`
    display: flex;
    align-items: center;
`;

const ContactBlockPhoneNumber = styled.a`
    color: ${({ theme }) => theme.headerContacts.phoneColor};
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    &:hover,
    &:active,
    &:visited {
        color: ${({ theme }) => theme.headerContacts.phoneColor};
        text-decoration: none;
    }
`;

const PhoneIconStyled = styled(PhoneIcon)`
    color: ${({ theme }) => theme.icon.brandColor1};
    margin-right: 4px;
    width: 16px;
    height: 16px;
`;

export const HeaderClass = (props: IProps) => {
    const {
        clearFilterParams,
        portalId,
        header,
        config,
        brandConfig,
        appConfig,
        phoneNumber,
        isMobile,
        favoriteCount,
        router,
    } = props;

    const { pageType } = router;
    const [isOpen, setIsOpen] = useState(false);
    const handleClickOpenMenu = () => setIsOpen(true);
    const handleClickCloseMenu = () => setIsOpen(false);

    const showBackButton = pageType === requestFormPage;
    const backButtonHandler = () => {
        if (pageType === requestFormPage) {
            window.sessionStorage.setItem('backFromSecondRequestFormPage', 'true');
        }
    };
    return (
        <>
            <div style={styles.container}>
                <BurgerButton onClick={handleClickOpenMenu} />
                <LogoWrapper>
                    <Logo
                        clearFilterParams={clearFilterParams}
                        portalId={portalId}
                        isMobile={isMobile}
                    />
                </LogoWrapper>
                <TrustBadges className="hidden-xs" style={styles.trustBadges} portalId={portalId} />
                <ContactBlockWrapper>
                    <ContactBlockTitle>{__('CallUsTitle', 'dreamlines')}</ContactBlockTitle>

                    <ContactBlockPhone>
                        <PhoneIconStyled width={20} height={20} />
                        <ContactBlockPhoneNumber
                            href={`tel:${phoneNumber}`}
                            dangerouslySetInnerHTML={{ __html: phoneNumber }}
                        />
                    </ContactBlockPhone>
                </ContactBlockWrapper>
                <NavigationMenuContainer
                    closeMenuClick={handleClickCloseMenu}
                    isOpen={isOpen}
                    mainNavigation={header.mainNavigationMobile}
                    exchangeRate={header.exchangeRate}
                    appConfig={appConfig}
                    brandConfig={brandConfig}
                    config={config}
                    favoriteCount={favoriteCount}
                />
            </div>
            <div id="alert-message"></div>
            {showBackButton && <BackLink backButtonHandler={backButtonHandler} />}
        </>
    );
};

const mapDispatchToProps = {
    clearFilterParams,
};

const mapStateToProps = (state: IState) => ({
    header: state.pageData.header,
    portalId: state.pageData.brandConfig.portalId,
    appConfig: state.pageData.appConfig,
    brandConfig: state.pageData.brandConfig,
    config: state.pageData.config,
    isMobile: state.pageData.deviceType === 'mobile',
    favoriteCount: state.favoritePage.favorites.length,
    favoritesUrl: state.pageData.brandConfig.slugsObj.favorites,
    phoneNumber: state.pageData.brandConfig.phoneNumber,
    router: state.router,
});

export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderClass);
