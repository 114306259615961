import { cruisePageBaseAction } from './actions/cruisePageBaseAction';
import { cruisePageRouteAction } from './actions/cruisePageRouteAction';
import { errorPageRouteAction } from './actions/errorPageRouteAction';
import { homePageBaseAction } from './actions/homePageBaseAction';
import { homePageRouteAction } from './actions/homePageRouteAction';
import { localizationAction } from './actions/localizationAction';
import { notFoundPageRouteAction } from './actions/notFoundPageRouteAction';
import { pageRouteAction } from './actions/pageRouteAction';
import { requestFormPageRouteAction } from './actions/requestFormPageRouteAction';
import { searchRouteAction } from './actions/searchRouteAction';
import { shipLivePositionPageRouteAction } from './actions/shipLivePositionRouteAction';
import { shipOverviewRouteAction } from './actions/shipOverviewRouteAction';
import { shipPageRouteAction } from './actions/shipPageRouteAction';
import { startPageRouteAction } from './actions/startPageRouteAction';
import { thankYouRouteAction } from './actions/thankYouRouteAction';
import { getRouteSlugs } from './helpers/routing';
import { blogRouteAction } from './actions/blogRouteAction';
import { IState } from './reducers';
import * as Sentry from '@sentry/react';

export const getRoutes = (state: IState) => {
    const slugs = getRouteSlugs(state.pageData.brandConfig.slugs);
    const searchPattern = `/${slugs.searchresults}`;
    const localizationPattern = new RegExp(`/([a-z]{2}-[A-Z]{2})`);
    const cruisePageRoutes = [
        {
            path: '/',
            action: cruisePageRouteAction,
        },
        {
            path: '/:sailNid/:cabinNid',
            action: requestFormPageRouteAction,
        },
        {
            path: '/:sailNid',
            action: requestFormPageRouteAction,
        },
    ];

    const homePageRoutes = [
        {
            path: '/',
            action: homePageRouteAction,
        },
    ];

    const pageRoutes = [
        {
            path: '/start',
            action: startPageRouteAction,
        },
        {
            path: `/${slugs.cruisepage}/:cruise`,
            action: cruisePageBaseAction,
            children: cruisePageRoutes,
        },
        {
            path: `/node/:cruise`,
            action: cruisePageBaseAction,
            children: cruisePageRoutes,
        },
        {
            path: searchPattern,
            action: searchRouteAction,
        },
        {
            path: `/${slugs['ship/overview']}`,
            action: shipOverviewRouteAction,
        },
        {
            path: `/${slugs['ship/detail']}/:shipName`,
            action: shipPageRouteAction,
        },
        {
            path: `/${slugs['ship/detail']}/:shipName/:positionSlug`,
            action: shipLivePositionPageRouteAction,
        },
        {
            path: `/${slugs['booking-thank-you']}`,
            action: thankYouRouteAction,
        },
        {
            path: '/error',
            action: errorPageRouteAction,
        },
        {
            path: '/404',
            action: notFoundPageRouteAction,
        },
        {
            path: '/blog',
            action: blogRouteAction,
        },
        {
            path: '/',
            action: homePageBaseAction,
            children: homePageRoutes,
        },
        {
            path: '*',
            action: pageRouteAction,
        },
    ];

    return [
        {
            path: localizationPattern,
            action: localizationAction,
            children: pageRoutes,
        },
        {
            path: '/',
            action: localizationAction,
            children: pageRoutes,
        },
        {
            path: '*',
            action: (context) => {
                Sentry.captureException(`Page not found ${JSON.stringify(context.path)}`);
            },
        },
    ];
};
