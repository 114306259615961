import { IRoundtripDetailPageReducer } from '../interfaces/IRoundtripDetailPage';
import * as actionTypes from '../consts/actionTypes';

const initialState: IRoundtripDetailPageReducer = {
    cmsData: {
        pageId: 0,
        uri: null,
        title: null,
        seoRobotsNoFollow: false,
        seoRobotsNoIndex: false,
        seoMetadesc: null,
        seoTitle: null,
        seoCanonical: null,
        seoTextBottom: null,
        seoTextTop: null,
        seoTextTopTitle: null,
        bannerHeadline: null,
        bannerSubline: null,
        bannerImage: null,
        attractionBoxesHeadline: null,
        recommendationBoxesHeadline: null,
        destinationListHeadline: null,
        teaserTitle: null,
        teaserImage: null,
        searchParameters: null,
        searchParametersString: null,
        discount: 0,
        showSearchFilter: false,
    },
    seo: {
        text: '',
        canonical: '',
        titleOg: '',
        robotsNoFollow: false,
        robotsNoIndex: false,
        metadesc: '',
    },
    attractionBoxContent: [],
    recommendationBoxContent: [],
    destinationPageList: [],
    breadcrumbs: [],
    searchParameters: {},
    searchParametersString: '',
};

export function roundtripDetailPage(
    state: IRoundtripDetailPageReducer = initialState,
    action,
): IRoundtripDetailPageReducer {
    switch (action.type) {
        case actionTypes.loadRoundtripDetailPageData:
            const content = action.payload;
            return { ...state, ...content };
        default:
            return state;
    }
}
