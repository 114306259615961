import styled from '@emotion/styled';
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { signUp } from '../../actions/newsletterAction';
import { Button } from '../../components/Button/Button';
import { config } from '../../config';
import * as Cookie from '../../helpers/cookie';
import { eventTracker } from '../../helpers/EventTracker/EventTracker';
import { TestSelector } from '../../helpers/TestSelector';
import { __ } from '../../helpers/TranslationService';
import { validateEmail } from '../../helpers/validation';
import { IPageDataReducer } from '../../reducers/pageData';
import { ArrowRightIcon } from '../Icons/ArrowRightIcon';
import { AttentionIcon } from '../Icons/AttentionIcon';
import { CheckMarkIcon } from '../Icons/CheckMarkIcon';
import { CrossIcon } from '../Icons/CrossIcon';
import { NewsletterIcon } from '../Icons/NewsletterIcon';
import { LoadingIndicatorControlled } from '../LoadingIndicator/LoadingIndicator';
import { BreakpointsEnum } from '../../helpers/breakpoints';

const StyledInput = styled.input<{ hasError: boolean }>`
    line-height: 20px;
    font-size: 15px;
    padding: 9px;
    outline: none;
    border: 1px solid
        ${({ hasError, theme }) => (hasError ? theme.colors.watermelon[100] : 'transparent')};
    width: 100%;
    flex-grow: 1;
    margin-top: 0;
    margin-right: 12px;
    border-radius: ${({ theme }) => theme.container.borderRadius};
`;
const StyledCheckMarkIcon = styled(CheckMarkIcon)`
    flex: 0 0 auto;
    margin-right: 10px;
    fill: ${({ theme }) => theme.footer.newsletterIconColor};
    align-self: 'flex-start';
`;

const StyledCrossIcon = styled(CrossIcon)`
    cursor: pointer;
    fill: ${({ theme }) => theme.colors.white[100]};
`;

const StyledCloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    padding: 0;
`;

const SuccessTitleWrapper = styled.div`
    display: flex;
    max-width: 605px;
    margin-bottom: 20px;
    aling-items: center;
`;

const SuccessTitle = styled.p`
    font-size: 20px;
    margin: 0;
`;

const SuccessSubTitle = styled.p`
    font-size: 15px;
    margin: 0;
`;

const SuccessWrapper = styled.div`
    color: ${({ theme }) => theme.footer.newsletterTitle};
    line-hight: 20px;
    z-index: 1;
`;

export const Container = styled.div<{ isCollapsed: boolean; imageUrl: string }>`
    position: relative;
    background: ${({ theme }) => theme.footer.newsletterBg};
    background-size: cover;
    background-repeat: no-repeat;
    border: 0;
    padding: 13px 15px 30px 15px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.27);

    display: flex;
    align-items: center;
    justify-content: center;

    padding: ${({ isCollapsed }) => (isCollapsed ? '10px' : '25px 10px')};

    @media (min-width: ${BreakpointsEnum.md}) {
        background-image: ${({ imageUrl, isCollapsed }) => (isCollapsed ? '' : imageUrl)};
    }
`;

export const OverlayWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.footer.newsletterOverlay};
    display: none;

    @media (min-width: ${BreakpointsEnum.md}) {
        display: block;
    }
`;

const ErrorWrapper = styled.div`
    color: ${({ theme }) => theme.colors.white[100]};
    margin-top: 5px;
    font-size: 15px;
    display: flex;
`;

const ErrorText = styled.span`
    color: ${({ theme }) => theme.colors.white[100]};
`;

const StyledAttentionIcon = styled(AttentionIcon)`
    fill: ${({ theme }) => theme.colors.white[100]};
    margin-right: 5px;
`;

const StyledNewsletterIcon = styled(NewsletterIcon)`
    margin-right: 18px;
    flex: 0 0 auto;
    height: 30px;
    fill: ${({ theme }) => theme.footer.newsletterIconColor};
    width: 30px;
`;

const GdprText = styled.div`
    color: ${({ theme }) => theme.footer.newsletterGdpr};
    font-size: 11px;
    margin-top: 8px;

    & a {
        text-decoration: underline;
        &:hover {
            color: ${({ theme }) => theme.footer.newsletterGdpr};
        }
    }
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 13px;
    position: relative;

    & > div {
        position: absolute;
        height: 100%;
        display: flex;
        right: 16px;
        top: 0;
    }
    & .loading-indicator {
        font-size: 3px;
        margin: auto;
    }
`;
const InputGroup = styled.div`
    display: flex;
`;

const Wrapper = styled.div<{ isCollapsed: boolean }>`
    max-width: ${({ isCollapsed }) => (isCollapsed ? '100%' : '605px')};
    z-index: 1;
    width: 100%;
`;

const TitleWrapper = styled.div`
    display: flex;
    color: ${({ theme }) => theme.footer.newsletterTitle};
    align-items: center;
`;

const Title = styled.p`
    font-size: 20px;
    line-height: 24px;
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)<{ open: boolean }>`
    fill: ${({ theme }) => theme.footer.newsletterTitle};
    transform: ${({ open }) => (open ? 'rotate(90deg)' : 'rotate(0)')};
    transition: transform 0.2s ease-in-out;
    margin-left: auto;
`;

interface StateProps {
    pageData: IPageDataReducer;
}

interface DispatchProps {
    signUp: Function;
}

interface OwnProps {
    testSelectorName?: string;
    setHideNewsletter: (hide: boolean) => void;
    isCollapsed?: boolean;
}

type IProps = StateProps & DispatchProps & OwnProps;

const shouldShowNewsletterForm = (portalId: string) => {
    return Cookie.readCookie('isSignedUpForNewsletter') !== 'true';
};

const NewsletterComponent = ({ pageData, signUp, isCollapsed }: IProps) => {
    const [state, setState] = useState({
        expanded: !isCollapsed,
        email: '',
        isLoading: false,
        isSignUpSuccessful: false,
        isError: false,
    });
    const { portalId } = pageData.brandConfig;
    const [showNewsletter, setShowNewsletter] = useState(shouldShowNewsletterForm(portalId));

    const onEmailChanged = (e) => {
        setState({
            ...state,
            email: e.target.value,
            isError: state.isError ? !validateEmail(e.target.value) : false,
        });
    };

    const onExpandHandler = () => {
        setState({ ...state, expanded: !state.expanded });
    };

    const onCloseHandler = () => {
        setShowNewsletter(false);
    };

    const handleSubmitClick = () => {
        if (!validateEmail(state.email)) {
            setState({ ...state, isLoading: false, isError: true });
            return;
        }
        const location = isCollapsed ? 'searchbanner' : 'footer';
        setState({ ...state, isLoading: true });
        signUp(state.email, `${portalId} ${location}`).then(
            () => {
                eventTracker.track({
                    category: 'newsletter',
                    action: 'footer',
                    label: 'cruisepage',
                });
                setState({ ...state, isLoading: false, isSignUpSuccessful: true });
            },
            () => {
                setState({ ...state, isLoading: false, isSignUpSuccessful: false });
            },
        );
    };

    // TEMPORARY DISABLED
    // if (!showNewsletter) {
    //     return null;
    // }

    const bgImage = `url(${config.images}/common/faq_contact_background.jpg)`;

    return (
        <Container isCollapsed={isCollapsed} imageUrl={bgImage}>
            <OverlayWrapper></OverlayWrapper>

            {!state.isSignUpSuccessful && (
                <Wrapper isCollapsed={isCollapsed}>
                    <TitleWrapper onClick={isCollapsed ? onExpandHandler : null}>
                        <StyledNewsletterIcon />
                        <Title>
                            {__(
                                'Register for our newsletter and get 30€ Onboardcredit',
                                'dreamlines',
                            )}
                        </Title>
                        {isCollapsed && <StyledArrowRightIcon open={state.expanded} />}
                    </TitleWrapper>

                    {state.expanded && (
                        <>
                            <InputGroup>
                                <InputWrapper>
                                    <LoadingIndicatorControlled isLoading={state.isLoading} />
                                    <StyledInput
                                        type="email"
                                        placeholder={__('Your E-Mail', 'dreamlines')}
                                        value={state.email}
                                        onChange={onEmailChanged}
                                        hasError={state.isError}
                                        {...TestSelector.getSelector('newsletterEmail')}
                                    />
                                </InputWrapper>
                                <Button onClick={handleSubmitClick} disabled={state.isLoading}>
                                    {__('Send', 'dreamlines')}
                                </Button>
                            </InputGroup>
                            {state.isError && (
                                <ErrorWrapper>
                                    <StyledAttentionIcon />
                                    <ErrorText>{__('incorrect email', 'dreamlines')}</ErrorText>
                                </ErrorWrapper>
                            )}
                            <GdprText
                                dangerouslySetInnerHTML={{
                                    __html: __('newsletter related GDPR text', 'dreamlines'),
                                }}
                            />
                        </>
                    )}
                </Wrapper>
            )}

            {state.isSignUpSuccessful && (
                <SuccessWrapper {...TestSelector.getSelector('newsletterMessage')}>
                    <SuccessTitleWrapper>
                        <StyledCheckMarkIcon />
                        <SuccessTitle>
                            {__('Thank you! Your registration was successful!', 'dreamlines')}
                        </SuccessTitle>
                    </SuccessTitleWrapper>

                    <SuccessSubTitle>
                        {__(
                            'you are just one step away from getting all top offers: Please go to your E-Mail provider and verify your E-Mail address.',
                            'dreamlines',
                        )}
                    </SuccessSubTitle>

                    {/* <StyledCloseButton onClick={onCloseHandler}>
                        <StyledCrossIcon />
                    </StyledCloseButton> */}
                </SuccessWrapper>
            )}
        </Container>
    );
};

const mapStateToProps = (state: StateProps) => ({
    pageData: state.pageData,
});
const mapDispatchToProps = { signUp };

export const Newsletter = connect<StateProps, DispatchProps, any>(
    mapStateToProps,
    mapDispatchToProps,
)(NewsletterComponent);
