import { colors } from './colors';
import { defaultThemedBackground } from './default';

export const c1 = {
    icon: {
        primary: colors.darkCharcoal[100],
        secondary: colors.darkCharcoal[70],
        brandColor1: colors.imperialBlue[100],
        brandColor2: colors.yellowSea[100],
    },
    link: {
        primary: colors.darkCornFlower[100],
        primaryHover: colors.darkCharcoal[100],
    },
    button: {
        primary: {
            background: colors.yellowSea[100],
            backgroundHover: colors.orangePeel[100],
            backgroundDisabled: colors.blanchedAlmond[100],
            text: colors.cetaceanBlue[100],
            textHover: colors.cetaceanBlue[100],
            textDisabled: colors.cetaceanBlue[50],
            icon: colors.cetaceanBlue[100],
            iconHover: colors.cetaceanBlue[100],
            iconDisabled: colors.cetaceanBlue[50],
        },
        secondary: {
            background: colors.milk[100],
            backgroundHover: colors.cosmicLatte[100],
            backgroundDisabled: colors.milk[100],
            border: colors.blanchedAlmond[100],
            text: colors.darkCharcoal[100],
            textHover: colors.darkCharcoal[100],
            textDisabled: colors.darkCharcoal[50],
            borderHover: colors.blanchedAlmond[100],
            icon: colors.darkCharcoal[100],
            iconHover: colors.darkCharcoal[100],
            iconDisabled: colors.darkCharcoal[50],
            borderDisabled: colors.blanchedAlmond[100],
        },
        tertiary: {
            background: colors.white[100],
            backgroundHover: colors.soapstone[100],
            backgroundDisabled: colors.soapstone[100],
            text: colors.darkCharcoal[100],
            textHover: colors.darkCharcoal[100],
            textDisabled: colors.darkCharcoal[50],
            icon: colors.darkCharcoal[100],
            iconHover: colors.darkCharcoal[100],
            iconDisabled: colors.darkCharcoal[50],
            border: colors.seashell[100],
            borderHover: colors.seashell[100],
            borderDisabled: colors.seashell[100],
        },
        quaternary: {
            background: colors.whiteSmoke[100],
            backgroundHover: colors.desertStorm[100],
            backgroundDisabled: colors.whiteSmoke[100],
            text: colors.darkCharcoal[100],
            textHover: colors.darkCharcoal[100],
            textDisabled: colors.darkCharcoal[50],
            icon: colors.darkCharcoal[100],
            iconHover: colors.darkCharcoal[100],
            iconDisabled: colors.darkCharcoal[50],
        },
        flat: {
            background: 'transparent',
            backgroundHover: 'transparent',
            backgroundDisabled: 'transparent',
            text: colors.imperialBlue[100],
            textHover: colors.imperialBlue[100],
            textDisabled: colors.cetaceanBlue[50],
            icon: colors.imperialBlue[100],
            iconHover: colors.imperialBlue[100],
            iconDisabled: colors.cetaceanBlue[50],
            border: 'none',
            borderHover: 'none',
            borderDisabled: 'none',
        },
    },
    alert: {
        succeed: {
            background: colors.turtleGreen[4],
            border: colors.turtleGreen[20],
            icon: colors.turtleGreen[100],
        },
        info: {
            background: colors.imperialBlue[4],
            border: colors.imperialBlue[20],
            icon: colors.imperialBlue[100],
        },
        attention: {
            background: colors.heatWave[4],
            border: colors.heatWave[20],
            icon: colors.heatWave[100],
        },
        warning: {
            background: colors.watermelon[4],
            border: colors.watermelon[20],
            icon: colors.watermelon[100],
        },
        neutral: {
            background: colors.soapstone[100],
            border: colors.whiteSmoke[100],
            icon: colors.gunmetal[100],
        },
    },
    toggleButton: {
        backgroundColor: colors.imperialBlue[20],
        backgroundColorActive: colors.imperialBlue[100],
    },
    background: {
        ...defaultThemedBackground,
        page: colors.desertStorm[100],
        layer: colors.white[100],
        brandColor1: colors.yellowSea[100],
        brandColor1Inactive: colors.yellowSea[20],
        brandColor2: colors.imperialBlue[100],
        brandColor3: colors.imperialBlue[100],
        brandColor4: colors.darkCornFlower[100],
        'brandColor4-90': colors.darkCornFlower[90],
        light1: colors.imperialBlue[4],
        light2: colors.lavender[100],
        searchBox: colors.imperialBlue[100],
    },
    text: {
        color: {
            primary: colors.darkCharcoal[100],
            primary2: colors.darkCharcoal[70],
            primaryInverse: colors.white[100],
            secondary: colors.darkCharcoal[50],
            brand: colors.imperialBlue[100],
            error: colors.watermelon[100],
        },
        size: {
            paragraph: '14px',
        },
    },
    actionBar: {
        backgroundColor: colors.cotton[100],
        borderColor: colors.lavender[100],
        actionColor: colors.imperialBlue[100],
    },
    headerContacts: {
        titleColor: colors.imperialBlue[100],
        phoneColor: colors.yellowSea[100],
    },
    pagination: {
        backgroundColor: colors.white[100],
        backgroundColorActive: colors.imperialBlue[100],
        borderColor: colors.white[100],
        borderColorActive: colors.imperialBlue[100],
        textColor: colors.imperialBlue[100],
        textColorActive: colors.white[100],
    },
    productPage: {
        tabs: {
            textColor: colors.darkCornFlower[100],
            textColorActive: colors.white[100],
            iconColor: colors.darkCornFlower[100],
            iconColorActive: colors.white[100],
            borderColor: colors.seashell[100],
            borderColorActive: colors.darkCornFlower[100],
            backgroundColor: colors.white[100],
            backgroundColorActive: colors.darkCornFlower[100],
        },
        specialsHeaderBackground: colors.imperialBlue[100],
        sectionHeader: {
            textColor: colors.darkCharcoal[100],
            backgroundColor: colors.lavender[100],
        },
        requestButtons: {
            primaryColor: colors.yellowSea[100],
            secondaryColor: colors.mango[100],
            hoverColor: colors.orangePeel[100],
        },
    },
    footer: {
        textColor: colors.taupe[100],
        socialIconsColor: colors.darkCornFlower[100],

        contactUsTextColor: colors.darkCharcoal[100],
        contactUsIconsColor: colors.black[100],
        contactUsBgColor: colors.seashell[100],

        breadcrumbsText: colors.imperialBlue[100],
        breadcrumbsTextActive: colors.darkCharcoal[50],
        breadcrumbsSeparator: colors.darkCharcoal[50],

        newsletterTitle: colors.white[100],
        newsletterOverlay: colors.cetaceanBlue[70],
        newsletterGdpr: colors.white[100],
        newsletterIconColor: colors.yellowSea[100],
        newsletterBg: colors.darkCornFlower[100],

        highlightsTextColor: colors.white[100],
        highlightsIconColor: colors.yellowSea[100],
        highlightsBackground: colors.imperialBlue[100],

        bottomBackground: colors.taupe[100],
        bottomTextColor: colors.white[100],
    },
};
