export const cruisePage = 'cruisepage';
export const searchResultPage = 'searchresults';
export const cruiselinePage = 'cruiseline';
export const shipOverviewPage = 'ship/overview';
export const shipDetailPage = 'shippage';
export const companyPage = 'lpCompanyDetail';
export const destinationPage = 'lpDestinationDetail';
export const thankYouPage = 'booking-thank-you';
export const privacyPage = 'privacy';
export const frontPage = 'frontPage';
export const requestFormPage = 'requestFormPage';
export const contactPage = 'contactPage';
export const standardContentPage = 'standardContentPage';
export const landingPage = 'landingPage';
export const favoritePage = 'favorite';
export const faqPage = 'faqPage';
export const themeOverviewPage = 'pageteaser-list';
export const lpDestinationOverview = 'lpDestinationOverview';
export const lpCompanyOverview = 'lpCompanyOverview';
export const newsletterPage = 'newsletterPage';
export const shipPositionPage = 'ship-position';
export const roundtripDetailPage = 'roundtripDetail';
export const roundtripOverviewPage = 'roundtripOverview';
export const companyTermsOverviewPage = 'companyTermsOverviewPage';
export const companyTermsDetailPage = 'companyTermsDetailPage';
export const welcomePage = 'welcomePage';
export const mediaOfferPage = 'mediaOfferPage';
