import { combineReducers } from 'redux';
import { router } from './router';
import { root } from './root';
import { ui } from './ui';
import { pageData } from './pageData';
import { search } from './search';
import { cruisePage } from './cruisePage/cruisePage';
import { shipData } from './shipData';
import { shipOverview } from './shipOverview';
import { standardContentPage } from './standardContentPage';
import { homePage } from './homePage';
import { companyDetailPage } from './companyDetailPage';
import { requestForm } from './requestForm/requestForm';
import { thankYouPage } from './thankYouPage';
import { contactPage } from './contactPage';
import { destinationDetailPage } from './destinationDetailPage';
import { landingPage } from './landingPage';
import { favoritePage } from './favoritePage';
import { faqPage } from './faqPage';
import { themeOverviewPage } from './themeOverviewPage';
import { destinationOverviewPage } from './destinationOverviewPage';
import { companyOverviewPage } from './companyOverviewPage';
import { newsletterPage } from './newsletterPage';
import { shipLivePositionPage } from './shipLivePositionPage';
import { roundtripDetailPage } from './roundtripDetailPage';
import { roundtripOverviewPage } from './roundtripOverviewPage';
import { mediaOfferPage } from './mediaOfferPage';
import { reviews } from './reviews';
import { companyTerms } from './companyTerms';
import { dynamicForm } from './dynamicForm/dynamicForm';
import { contactForm } from './contactForm/contactForm';
import { Reducer } from 'react';
import { abTests } from './abTests';

const state = {
    ui,
    router,
    root,
    pageData,
    shipData,
    shipOverview,
    cruisePage,
    standardContentPage,
    search,
    homePage,
    companyDetailPage,
    requestForm,
    thankYouPage,
    contactPage,
    destinationDetailPage,
    landingPage,
    favoritePage,
    faqPage,
    themeOverviewPage,
    destinationOverviewPage,
    companyOverviewPage,
    newsletterPage,
    shipLivePositionPage,
    roundtripDetailPage,
    roundtripOverviewPage,
    mediaOfferPage,
    reviews,
    companyTerms,
    dynamicForm,
    contactForm,
    abTests,
};

export const reducers = combineReducers(state);

export type IState = {
    // I know, right?! Fancy O.o
    [K in keyof typeof state]: ReturnType<ReturnType<Reducer<typeof state[K], {}>>>;
};
