import * as React from 'react';
import styled from '@emotion/styled';
import { acqa } from '../../consts/variables';
import { __ } from '../../helpers/TranslationService';
import { ArrowLeftIcon } from '../Icons/ArrowLeftIcon';
import { ArrowRightIcon } from '../Icons/ArrowRightIcon';

const defaultUncollapsedSpace = 80;
const defaultUncollapsedSpaceWithHeadline = 120;
const footerSpace = 15;

interface IState {
    collapsed: boolean;
    childHeight: string;
}

interface IProps {
    onClick?: () => void;
    wrapperStyle?: any;
    text: string;
    headline?: string;
    uncollapsedSpace?: number;
    collapseButtonText?: string;
}

const CollapseButton = styled.button`
    color: ${({ theme }) => theme.link.primary};
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
    border: none;
    padding: 0;
    background-color: transparent;
`;

const ArrowRightIconStyled = styled(ArrowRightIcon)`
    fill: ${({ theme }) => theme.link.primary};
`;

const ArrowLeftIconStyled = styled(ArrowLeftIcon)`
    fill: ${({ theme }) => theme.link.primary};
`;
export class CollapsibleText extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            childHeight: 'auto',
        };
    }
    uncollapsedSpace = this.props.uncollapsedSpace
        ? this.props.uncollapsedSpace
        : this.props.headline
        ? defaultUncollapsedSpaceWithHeadline
        : defaultUncollapsedSpace;
    childRef = null;
    getChildRef = (ref) => (this.childRef = ref);
    calculateChildHeight = () => this.setState({ childHeight: this.childRef.clientHeight });

    componentDidMount() {
        this.calculateChildHeight();
        window.addEventListener('resize', this.calculateChildHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateChildHeight);
    }

    collapseButtonHandler = () => {
        if (this.props.onClick) this.props.onClick();

        this.setState({ collapsed: !this.state.collapsed });
    };

    collapseButton = () => {
        const btnText = __(this.props.collapseButtonText + ' more', 'dreamlines');
        const showLess = this.props.collapseButtonText ? btnText : __('show less', 'dreamlines');
        const showMore = this.props.collapseButtonText ? btnText : __('load more', 'dreamlines');

        const text = this.state.collapsed ? showMore : showLess;

        const arrowButton = this.state.collapsed ? (
            <ArrowRightIconStyled key="arrow-right" width={12} height={12} />
        ) : (
            <ArrowLeftIconStyled key="arrow-left" width={12} height={12} />
        );

        return (
            <CollapseButton onClick={this.collapseButtonHandler}>
                {!this.state.collapsed && arrowButton}
                <span>{text}</span>
                {this.state.collapsed && arrowButton}
            </CollapseButton>
        );
    };

    render() {
        const { wrapperStyle } = this.props;
        const childHeight =
            this.state.childHeight === 'auto'
                ? 'auto'
                : this.state.collapsed
                ? this.uncollapsedSpace
                : this.state.childHeight + footerSpace;
        return (
            <div className="collapseText" style={{ ...wrapperStyle }}>
                <div className="collapseTextChild" style={{ height: childHeight }}>
                    {this.props.headline && <h2>{this.props.headline}</h2>}
                    <div
                        ref={this.getChildRef}
                        dangerouslySetInnerHTML={{ __html: this.props.text }}
                    />
                </div>
                {this.collapseButton()}
            </div>
        );
    }
}
