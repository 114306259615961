import * as qs from 'qs';
import * as actionTypes from '../consts/actionTypes';
import * as http from '../helpers/http';
import { Store } from '../configureStore';

export const homePageBaseAction = (context) => {
    const { queryParams, waitForAction } = context;
    const store: Store = context.store;

    store.dispatch({ type: actionTypes.showLoadingIndicator });

    const { root, pageData } = context.store.getState();

    if (waitForAction) {
        store.dispatch({ type: actionTypes.loadHomePageDataPending });

        const device = pageData.deviceType === 'desktop' ? 'desktop' : 'mobile-temp';

        const queryStrings =
            Object.keys(queryParams).length > 0 ? `?${qs.stringify(queryParams)}` : '';

        const url = `${pageData.appConfig.apiHost}/frontpage/${device}${queryStrings}`;

        return http.get(url).then(
            (resp) => {
                store.dispatch({
                    type: actionTypes.loadHomePageData,
                    payload: resp,
                });
                return context.next();
            },
            (err) => {
                throw new Error('Error loading homepage data' + err);
            },
        );
    }

    return context.next();
};
