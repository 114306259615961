import * as actionTypes from '../consts/actionTypes';
import { IRoundtripOverviewPageReducer } from '../interfaces/IRoundtripOverviewPage';

const initialState: IRoundtripOverviewPageReducer = {
    pageId: null,
    uri: null,
    title: null,
    headerHeadline: null,
    headerImage: null,
    headerText: null,
    seoTextTop: null,
    seoTextBottom: null,
    seo: {
        text: null,
        canonical: null,
        titleOg: null,
        robotsNoFollow: null,
        robotsNoIndex: null,
        metadesc: null,
    },
    destinationBottomList: [],
    breadcrumbs: [],
    roundtrips: [],
};

export function roundtripOverviewPage(
    state: IRoundtripOverviewPageReducer = initialState,
    action,
): IRoundtripOverviewPageReducer {
    switch (action.type) {
        case actionTypes.loadRoundtripOverviewPageData:
            return action.payload;
        default:
            return state;
    }
}
